var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-list-video"
  }, [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.boards, function (board) {
    return _c('div', {
      key: board._id,
      staticClass: "col-6 col-sm-4"
    }, [_c('div', {
      staticClass: "board-list-video__inner"
    }, [_c('a', {
      attrs: {
        "href": ""
      },
      on: {
        "click": function ($event) {
          return _vm.$emit('click', board);
        }
      }
    }, [_c('div', {
      staticClass: "board-list-video__img-wrap"
    }, [_c('div', {
      staticClass: "board-list-video__img",
      style: {
        backgroundImage: `url('${board.thumb}')`
      }
    })]), _c('div', {
      staticClass: "board-list-video__con"
    }, [_c('div', {
      staticClass: "board-list-video__tit-wrap"
    }, [_c('div', {
      staticClass: "board-list-video__tit ellip"
    }, [_c('span', [_vm._v(_vm._s(board.subject))])])])])])])]);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }